.spinner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes pulse {
    0% {
      top: 33px;
      left: 33px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 5.9px;
      left: 5.9px;
      width: 54.2px;
      height: 54.2px;
      opacity: 0;
    }
  }
}

.ldio div {
  position: absolute;
  // box-sizing: content-box;
  border-color: #151515;
  border-width: 26px;
  border-style: solid;
  border-radius: 50%;
  animation: pulse 1.85s cubic-bezier(0,0.2,0.8,1) infinite;
}

.ldio div:nth-child(2) {
  border-color: #151515;
  animation-delay: -0.93s;
}

.loadingio-spinner-ripple-mryceesfvs {
  width: 118px;
  height: 118px;
  display: inline-block;
  overflow: hidden;
}

// .ldio {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   transform: translateZ(0) scale(1);
//   backface-visibility: hidden;
//   transform-origin: 0 0; /* see note above */
// }