
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 5.5rem;
  // height: max-content;
  padding: 0 1.5rem;
  background-color: #151515;
  box-shadow: 0 0 12px #00000066;
  font-size: 14px;
  // overflow: scroll;

  a {
    color: inherit;
  }

  &__item {
    box-sizing: border-box;
    height: 100%;
    padding: 2.5rem 1rem;
    word-break: break-word;

    &-links {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // width: min-content;

      a {
        display: table;
        // padding: 0 .5rem;
      }
    }

    &--right {
      min-width: 20%;
      max-width: 30%;

      &__copyright {
        text-align: right;
      }
    }
    &-cookies{
      text-align: right;
    }
  }
}
