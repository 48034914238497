// /* fix it */
// .frame {
//   width: min-content !important;
// }

.about {
  width: min-content;
  padding: 2.75rem 4.75rem;

  &__logo {
    display: block;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
  }

  &__addition-info {
    margin: 1.25rem 0;
    border: 1px solid #e6e6e6;
    border-radius: 7px;
    padding: .75rem 1.25rem;
    background-color: #f9f9f9;
  }
}

.quiz {
  width: min-content;
  padding: 1.5rem 3rem;
  padding-bottom: 3rem;

  &__question {
    position: relative;
    
    &-counter {
      margin-right: .5rem;
      margin-bottom: 1rem;
      text-align: right;
      color: #878787;
      font-weight: bold;
      font-size: 18px;
    }

    .picture-box {
      position: relative;
      min-height: 20vh;
      height: auto;
      max-height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__picture {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 95%;
        max-width: 90%;
        border-radius: 7px;
        margin: 0 auto;
        // background-color: #f4f4f4;
        text-align: center;
        filter: grayscale(5%);
        // transition: transform .3s ease-out, scale .3s ease-out, box-shadow .2s ease-out;
      }
    }

    &-text {
      display: block;
      margin-top: 1.5rem;
    }
  }

  &__answer {

    &-options {
      margin-top: 1.45rem;

      &-link {
        // color: #000000;
        // text-decoration: underline;
        box-decoration-break: clone;
        background-color: #0000001a;
        border-radius: 5px;
        padding: 0 .25rem;
        font-family: inherit;
        filter: brightness(.9);
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        margin-bottom: .5rem;
        padding: .75rem .5rem;
        background-color: transparent;
        text-align: left;
        line-height: 1.25rem;
        font-size: 16px;
        color: #454545;
        cursor: pointer;
        transition: color .2s ease-out, .3s ease-out, box-shadow .3s ease-out, border-color .4s ease-out, background-color .2s ease-out;

        &-text {
          padding: 0 .5rem;
        }
  
        &:hover {
          // background-color: #f9f9f9;
          // font-family: 'Proxima Nova Lt';
          color: #454545;
          box-shadow: 2px 4px 5px #0000000d;
          transform: scale(1.025);
        }
  
        &_disabled {
          // background-color: #fbfbfb;
          color: #8c8c8c !important;
          cursor: default;

          &:hover {
            box-shadow: inherit;
            // font-family: inherit;
            color: inherit;
            transform: inherit;
          }
        }

        &_correct, &_wrong {
          transform: scale(1.02);
          transition: border .15s ease-out;

          &:hover {
            transform: scale(1.02);
          }
        }

        &_correct {
          border: 1px solid #c8dcd140;
          border-left: 5px solid #6f8a83;
          background-color: #e6f3e9 !important; // #b7dcbe54
          color: #6f8a83 !important;
          font-family: 'Proxima Nova Lt';
          font-weight: 600;
          box-shadow: 2px 4px 5px #669e901f !important;
          // transform: scale(1.025) !important;

          .quiz__answer-options-link {
            color: #5a716b;
          }
        }
  
        &_wrong {
          border-color: #efefef;
          border-left: 5px solid #e47f7f;
          background-color: #ffeded;
          color: #303030 !important;
          font-family: 'Proxima Nova Lt';
          font-weight: 600;
        }
      }
    }
  
    &-btn {
      margin-top: 1.25rem;
      transition: .2s ease-out, opacity .25s ease-out;

      &_hidden {
        opacity: 0;
        cursor: default;
      }
    }
  }
}

@media only screen 
and (max-width : 1599px) {
  .frame {
    min-width: 40% !important;
  }
}

@media only screen 
and (max-width : 1366px) {
  .frame {
    min-width: 50% !important;
  }
}

// @media only screen 
// and (max-width : 1365px) {
//   .frame {
//     min-width: 66% !important;
//   }
// }

@media only screen 
and (max-width : 1023px) {
  .frame {
    min-width: 66% !important;
  }
}

@media only screen 
and (max-width : 768px) {
  .frame {
    min-width: 90% !important;
  }
}

@media only screen 
and (max-width : 454px) {
  // .frame {
  //   width: 100% !important;
  // }
  .quiz {
    padding: 1.5rem 2.25rem 3rem;

    // &__question {
    //   .picture-box {
    //     &__picture {
    //       width: 100%;
    //     }
    //   }
    // }
  }
}