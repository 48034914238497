.promo {
  position: relative;
  box-sizing: content-box;
  width: 75%;
  padding: 2rem 3.5rem 2rem 3.5rem;
  background: linear-gradient(279.61deg,
  rgba(208, 255, 245, 0.16) 10.75%,
  rgba(255, 206, 96, 0.16) 80.48%,
  rgba(255, 206, 96, 0.16) 100%), #FFFFFF;
  
  @media only screen and (max-width: 959px) {
    padding: 2rem 2.5rem 2rem 2.5rem;
  }

  @media only screen and (max-width: 773px) {
    background: linear-gradient(279.61deg, rgba(208, 255, 245, 0.16) 10.75%, rgba(255, 206, 96, 0.16) 80.48%, rgba(255, 206, 96, 0.16) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }

  .grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, 14rem);
    justify-content: center;
    gap: 2rem 1.5rem;
    padding-bottom: 4.5rem;
    margin: 0 1rem;
    min-height: 62vh; //fix

    @media only screen and (max-width:	1365px) {
      grid-template-columns: repeat(auto-fit, 12.5rem);
    }

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  {
      grid-template-columns: repeat(auto-fit, 12.5rem);
    }
    @media only screen and (max-width: 959px) {
      margin-top: 2rem;
    }


    @media only screen and (max-width: 773px) {
      min-height: 0;
      gap: 1.25rem 1rem;
      grid-template-columns: repeat(auto-fit, 12rem);
    }
    @media only screen and  (max-width: 454px) {
      gap: 1rem .75rem;
      grid-template-columns: repeat(auto-fit, 10.5rem);
      margin: 1.5rem .5rem 0 .5rem;
    }
  }

  .emoji-badge {
    height: 6.25rem;
    position: absolute;
    top: -40px;
    right: -20px;
    transform: rotateZ(30deg);

    &:hover {
      animation: vibrate .25s linear infinite both;
      animation-delay: .2s;
    }
  }

  .msg {
    display: flex;
    align-items: center;
    //gap: 1.75rem;
    margin-left: 3.5rem;
    margin-bottom: 2.25rem;

  
    &__badge {
      height: 6.25rem;
      margin-right: 1.75rem;

      @media only screen and (max-width: 959px) {
        height: 5rem;
        margin-right: 1rem;
        &-shadow{
          filter: drop-shadow(4px 2px 15px rgba(0, 0, 0, 0.2));
        }
      }

      @media only screen and (max-width: 454px) {
        margin-right: .75rem;
        height: 4.5rem;
      }
     
      &-animate{
        animation: shake-lr 1s cubic-bezier(.455,.03,.515,.955) both;
        animation-delay: .5s;
      }
    }

    &__text {
      text-align: left;
      flex-direction: row;

      &--primary {
        font-size: 34px;
        line-height: 100%; 
        color: #151515;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        & q{
          transition: all .4s ease-out;
        }

        :hover{
          cursor: pointer;
        }
      }

      &--secondary {
        font-weight: bold;
        //font-size: 28px;
        font-size: 23px;
        line-height: 100%;
        color: #151515; 
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      @media only screen and (max-width: 959px) {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &--primary {
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 100%;
        }
        &--secondary {
          padding-top: .25rem;
          //font-family: Proxima Nova Lt;
          font-style: normal;
          font-weight: normal;
          font-size: 23px;
          line-height: 100%;
        }
      }
      @media only screen and (max-width: 454px) {
        filter: none;
        &--primary {
          font-size: 24px;
        }
        &--secondary {
          font-size: 20px;
        }
      }
    }

    &__message{
      margin: 1.5rem auto .5rem;
      font-family: 'Proxima Nova Lt';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #000;

      @media only screen and (max-width: 773px) {
        font-size: 22px;
        line-height: 27px;
      }
    } 
  }
}


@media only screen and (max-width: 	1440px) {
  .promo {
     width: 90%;
  }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
  .promo {
    width: 85%;
 }

}



@media only screen and (max-width: 959px) {
  .promo {
    height: auto !important;
    margin-top: 0 !important;
    
    .emoji-badge {
      display: none;
    }
    .msg{
      margin-left: 1.5rem;
      margin-bottom: 1.75rem;
    }
  }
}

@media only screen and (max-width: 773px) {
  .promo{
    padding: 2rem 0 2.25rem 0;

    .msg{
      margin: 0 .25rem 1.5rem 1.25rem;
    }
  }
}

@media only screen and (max-width: 454px) {
  .promo{
    padding: 1.5rem 0 2.25rem 0;

    .msg{
      margin: 0 .25rem 1rem 1rem;
    }
  }
}



 //MARK: Animations 
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

@keyframes vibrate {
  0% {
    transform: translate(0) rotateZ(30deg);
  }
  20% {
    transform: translate(-2px, 2px) rotateZ(30deg);
  }
  40% {
    transform: translate(-2px, -2px) rotateZ(30deg);
  }
  60% {
    transform: translate(2px, 2px) rotateZ(30deg);
  }
  80% {
    transform: translate(2px, -2px) rotateZ(30deg);
  }
  100% {
    transform: translate(0) rotateZ(30deg);
  }
}

.title-fade{
  &-enter {
    &{
      opacity: .1;
      transform: translate(40px, 0);
    }
    &-active{
      opacity: 1;
      transform: translate(-5px, 0);
      transition: all 400ms ease-out;
    }
    &-done{
      transition: all 100ms ease-out;
      transform: translate(0, 0);
    }
  } 
}

.title-fade-lite{
  &-enter {
    &{
      opacity: 0;
      transform: translate(40px, 0);
    }
    &-done{
      transition: all 300ms ease-out;
      transform: translate(0, 0);
    }
  } 
}


