.home {
  width: 100%;

  .frame {
    width: 66%;
    height: max-content;
    margin: 4rem auto;
    padding: 3rem 5rem;
    background-color: white;
    word-break: break-all;
  }
}