.form {
  // width: 28%;
  // padding: 0rem 3rem;
  // padding-bottom: 2.75rem;

  &__description {
    margin-top: 3rem;
  }

  &__label {
    display: inline-block;
    color: #a5a5a5;
    padding-left: .25rem;
    line-height: 1.75rem;
    font-size: 15px;
  }

  &__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 7px;
    margin-bottom: 1rem;
    padding: .75rem 1rem;
    background-color: #fcfcfc;
    font-size: 16px;
    transition-property: border-color, background-color, box-shadow;
    transition: .3s ease-out;

    &::placeholder {
      color: #b4b4b4;
    }

    $box-shadow-hover: 0 0 6px #00000020;

    &:hover {
      box-shadow: $box-shadow-hover;
      background-color: #fff;
      border-color: #e3e3e3;
    }

    &:focus {
      border-color: #151515;
      background-color: #ffffff;
      // box-shadow: 0 0 5px 1px #00000030 !important;
    }
    
    &:invalid {
      box-shadow: none;

      &:hover {
        box-shadow: $box-shadow-hover;
      }
    }

    &_invalid {
      border-color: #ff000020 !important;
      background-color: #ff00000a !important;
      color: #f0b1b1;

      // &:-webkit-autofill {
      //   -webkit-text-fill-color: #f0b1b1;
      //   -webkit-box-shadow: 0 0 0px 5000px #fff5f5 inset;

      //   &:hover, &:focus {
      //     -webkit-text-fill-color: #f0b1b1;
      //     -webkit-box-shadow: 0 0 0px 5000px #fff5f5 inset;
      //   }
      // }

      &:hover {
        box-shadow: 0 0 5px 1px #ff000021 !important;
      }
    }

  }
  &__terms {
    margin-top: 2rem;

    &-checkbox, &-label-for-checkbox {
      vertical-align: middle;
    }

    &-label-for-checkbox {
      margin-left: .25rem;
      color: #000000;
      font-size: 14px;
    }
  }

  &__submit-btn {
    width: 100%;
    padding: .75rem 1rem;
    font-size: 16px;
    border: 1px solid #151515;
    border-radius: 7px;
    margin-top: 1rem;
    background-color: #151515;
    color: #ffffff;
    letter-spacing: .3px;
    transition: .2s ease-out;
    cursor: pointer;
  }

  &__submit-btn:hover {
    box-shadow: 0 4px 5px #00000054;
    text-shadow: 0 0 2px #ffffff9f;
  }
}

@media only screen 
and (max-width : 454px) {
  .form {
    // padding: 0 2.25rem 2.25rem !important;
  
    &__description {
      margin-top: 2.25rem !important;
    }
  }
}
