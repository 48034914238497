.promocodes {

    &__section{
      display: flex;
      flex-direction: column;
      width: 100%;
      align-self: flex-start;

      &-main{
       display: flex;
       min-height: 91px;
      }

      &-top{
        display: flex;
        justify-content: flex-start;
        min-height: 56px;
        padding-left: .5rem;

      }

      &-left{
        display: flex;
        align-self: stretch;
      }
      &-right{   
        display: flex;
        align-self: flex-end; 
      }

      &-center{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-self: flex-start;
        flex-grow: 2; 
        padding-left: .5rem;
      }

      @media only screen and (max-width: 1279px) {

        margin-left: .25rem;

        &-top{
          min-height: 45px;
        }
      }
      @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
          margin-left: .25rem;

          &-top{
            min-height: 45px;
          }
        }
        @media only screen and (max-width: 454px) {
          margin-left: 0;

          &-top{
            min-height: 35px;
          }
          &-main{
            min-height: 80px;
          }
        }
    }

    & a{
      margin: 0 0 1.25rem;
      @media only screen and (max-width: 454px) {
        margin: 0 0 1rem;
      }
    }

    a &__item{
      margin: 0;
    }
  
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 10px;
      background-color: #f9fefa;
      box-shadow: -5px 3px 5px 1px #00000003;
      min-height: 147px;
      margin: 0 0 1.25rem;

      &:hover{
        transform: scale(1.01);
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
        transition: transform .3s ease-out, box-shadow .4s ease-in-out;
      }


      &-icon {
         max-height: 147px;
         width: 147px;
         background-image: linear-gradient(rgba(0, 0, 0, 0.15) 57%, rgba(0, 0, 0, 0) 0%);
         background-position: right;
         background-size: 4px 1.25rem;
         background-repeat: repeat-y;
         display: flex;
         justify-content: center;
         align-items: center;
       
        &-img{
          height: 100%;
          width: 147px;
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          border-radius: 10px 0 0 10px;
        }

        &-text {   
          margin: 0 .5rem;
          font-family: Proxima Nova Lt;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          color: #000;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);      
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          &-sm{
            margin: 0 .5rem;
            font-family: Proxima Nova Lt;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            text-align: center;
            color: #000;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);      
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
  
      &-title {
        word-break: break-word;
        font-style: normal;
        font-weight: normal;
        height: 36px;
        font-size: 20px;
        line-height: 23px;
        color: #000;
        padding: .5rem .25rem;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media only screen and (max-width: 1279px) {
          padding: .25rem .15rem;
          font-size: 18px;
          line-height: 20px;
          height: 56px;
          -webkit-line-clamp: 3;
        }
        @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
          padding: .25rem .15rem;
          font-size: 18px;
          line-height: 20px;
          height: 56px;
          -webkit-line-clamp: 3;
        }
        @media only screen and (max-width: 454px) {
          font-size: 15px;
          line-height: 17px;
          height: 48px;
        }
      }

      &-labels{
    
        padding: .75rem .25rem .5rem .25rem; 
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        &-elem{
          border: 2px solid #151515;
          box-sizing: border-box;
          border-radius: 40px;
          padding: .25rem 1rem;
          margin-right: .75rem; 
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          background-color: #f9fefa;
          color: #151515;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
          
          &-spec{
            box-sizing: border-box;
            font-family: Proxima Nova Lt;
            padding: .25rem 1rem;
            margin-right: .75rem; 
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #E6E6E6;
            background: #151515;
            border: 2px solid #151515;
            box-sizing: border-box;
            border-radius: 40px;
          }
        }

        @media only screen and (max-width: 1279px) {
          
          padding: .5rem .15rem .25rem .15rem; 

          &-elem{
            padding: .15rem .75rem;
            margin: .25rem .5rem 0 0; 
            font-size: 15px;
            line-height: 17px;
            color: #000;
            border: 1px solid #151515;

            &-spec{
              padding: .15rem .75rem;
              margin: .25rem .5rem 0 0; 
              font-size: 15px;
              line-height: 15px;
              color: #E6E6E6;
              border: 1px solid #151515;
            }
          }
        }

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
          padding: .5rem .15rem .25rem .15rem; 

          &-elem{
            padding: .15rem .75rem;
            margin: .25rem .5rem 0 0; 
            font-size: 15px;
            line-height: 17px;
            color: #000;
            border: 1px solid #151515;

            &-spec{
              padding: .15rem .75rem;
              margin: .25rem .5rem 0 0; 
              font-size: 15px;
              line-height: 15px;
              color: #E6E6E6;
              border: 1px solid #151515;
            }
          }
        }
        @media only screen and (max-width: 773px) {
          height: 27px;
          overflow: hidden;
        }

        @media only screen and (max-width: 454px) {
          padding: .25rem .15rem .25rem .15rem;
          height: 23px;
          &-elem{
            padding: .1rem .6rem;
            margin: .25rem .4rem 0 0; 
            font-size: 14px;
            line-height: 16px;

            &-spec{
              padding: .1rem .6rem;
              margin: .25rem .4rem 0 0; 
              font-size: 14px;
              line-height: 15px;
            }
        }
      }
    }

      &-time{
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #F57373;
        padding: .5rem .25rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 15px;

        @media only screen and (max-width: 	1279px) {
          padding: .25rem .25rem .5rem .25rem;
          font-size: 18px;
          line-height: 20px;
          height: 12px;

        }

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
          padding: .25rem .25rem .5rem .25rem;
          font-size: 18px;
          line-height: 20px;
          height: 14px;
        }

        @media only screen and (max-width: 959px) {
          align-self: flex-end;
          text-align: end;
          font-size: 16px;
          line-height: 18px;
          height: 12px;
          margin-right: .5rem;
        }


        @media only screen and (max-width: 773px) {
          padding: .15rem 1.5rem .5rem .25rem;
        }

        @media only screen and (max-width: 454px) {
          font-size: 14px;
          line-height: 16px;
          height: 9px;
        }
        
      }
  
      &-link {
        padding: .25rem .85rem;
        border: 1px solid #303030;
        border-radius: 3px;
        cursor: pointer;
        transition: box-shadow .25s ease-out, background-color .3s ease-out;
  
        &:hover {
          background-color: #fff2d47a;
          box-shadow: 0 3px 6px #00000015;
        }
      }
  

      &-code {
        max-width: max-content;
        height: max-content;
        border: 2px solid transparent;
        border-radius: 10px;
        padding: .25rem 2rem;
        margin: .5rem 2.5rem 1.25rem .25rem ;
        background-color: #151515;
        color: #FFFFFF;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 27px;
        cursor: pointer;
        transition: all .35s ease-out;
        text-align: center;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          box-shadow: 3px 3px 10px 0px #15151525;
          background-color: #303030;
          color:  #E6E6E6;
        }
  
        &:focus, &:active {
          border: 2px dashed #303030;
          background: #fff7e5;
          color: #303030;
  
          &:hover {
            color: #151515;
          }
        }
  
        &::selection {
          background-color: transparent;
        }
        @media only screen and (max-width: 1279px) {
          font-size: 21px;
          line-height: 24px;
          padding: .25rem 1.5rem;
          margin: .5rem 2.5rem 1rem .25rem ;
        }

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  {
          font-size: 21px;
          line-height: 24px;
          padding: .25rem 1.5rem;
          margin: .5rem 2.5rem 1rem .25rem ;
        }
      }

      @media only screen and (max-width: 1279px) {
        &{
          min-height: 141px;
        }

        &-icon {
          width: 141px;
          max-height: 141px;
          
          &-img{
            width: 141px;
          }

          &-text{
            font-size: 30px; 
            line-height: 37px;
            &-sm{
              font-size: 26px;
              line-height: 34px;
            }
          }
        }
      }

      @media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
        only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  {
          &{
            min-height: 141px;
          }
  
          &-icon {
            width: 141px;
            max-height: 141px;
            
            &-img{
              width: 141px;
            }
  
            &-text{
              font-size: 30px; 
              line-height: 37px;
              &-sm{
                font-size: 26px;
                line-height: 34px;
              }
            }
          }
        }
        @media only screen and (max-width: 959px) {
          &:hover{
            transform: none;
            box-shadow: none;
          }
        }
        @media only screen and (max-width: 454px) {
          &{
            min-height: 115px;
          }
    
          &-icon {
            width: 115px;
            max-height: 115px;
            background-size: 3px 1.25rem;

            &-img{
              width: 115px;
            }
    
            &-text{
              font-size: 24px; 
              line-height: 30px;
              &-sm{
                font-size: 21px;
                line-height: 27px;
              }
            }
          }
        }
    }
}
 
  