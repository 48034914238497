

.promo {

  .grid {
   

    .card {
      position: relative;
      box-sizing: border-box;
      height: max-content;
      width: 100%;
      padding: 1.25rem;
      border: 1px solid #F9F9F9;
      background-color: #fff;
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      transition: transform .3s ease-out, box-shadow .4s ease-in-out;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
      }

      @media only screen and (max-width: 773px) {
       
        padding: 1rem .5rem 1.25rem 0.5rem;

        &:hover{
          transform: none;
          box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.15);
        }
      }
      
      @media only screen and (max-width: 454px) {
        padding: .75rem .4rem 1rem 0.4rem;
      }

      &__thumb {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 7rem;
      }

      &__name{
        font-size: 1.4rem;
        line-height: 100%;
      }

      &__codes{
        font-weight: 600;
        font-size: 17px;
        line-height: 17px;
        text-align: center;
        color: #000;
        margin: .75rem auto 2rem auto;

        @media only screen and (max-width: 773px) {
          font-size: 16px;
          line-height: 16px;
        }

        @media only screen and (max-width: 454px) {
          margin: .5rem auto 1.5rem auto;
        }
      }

      &__btn {
        height: 2rem;
        border-radius: 2rem;
        line-height: 0;

        @media only screen and (max-width: 773px) {
          width: 90%;
          box-shadow: none!important;
        }
      }
    }
  }
}