#p404 {
  width: 50%;

  .frame {
    width: 100%;
    padding: 4rem 2.75rem;
    text-align: center;

    .message-box {
      &__code, &__message {
        font-weight: 600;
      }      

      &__code {
        font-size: 5rem;
      }
  
      &__message {
        font-size: 2rem; 
      }
    }
  }
}