.icon {
  transition: .4s ease-out;
  margin: 0 .5rem;

  &__img{
    vertical-align: middle;
    pointer-events: none; 
  }

  &__text{
    vertical-align: middle;
  }

  &:hover, &:focus, &:active {
    filter: invert(.5)!important;
    transform: scale(1.035);
    @media only screen and (max-width: 1024px) {
      transform: none;
    }
  }
}