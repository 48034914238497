.navbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 4.5rem;
  padding: 0 3.25rem;
  background-color: #151515;
  box-shadow: 0 0 15px 2px #00000017;

  @media only screen and (min-width: 2560px) {
    height: 6rem;
  }

  @media only screen and (max-width: 773px) {
    height: 3.875rem;
  }

  &__links, &__brand-name {
    color: #f1f1f1;
    font-weight: 600;
    transition: text-shadow .25s ease-out;
    
    &:hover {
      text-shadow: 0 0 5px rgba(255, 255, 255, .7);
    }
  }

  &__brand-name {
    font-size: 1.35rem;
    font-family: 'Proxima Nova Lt';
    letter-spacing: .1px;
    margin-right: .75rem;
  }

  &__links {
    display: inline-flex;

    a {
      margin: 0 .75rem;
      color: inherit;
      border-bottom: 1px solid transparent;

      &:focus, &:active {
        border-color: #f1f1f1;
        transition: border-color .2s ease-out;
      }
    }

    &--active {
      border-color: #f1f1f1 !important;
    }
  }

  &__logo{
    margin: .5rem auto;

    &-img{
      vertical-align: middle;
      height: 42px;
      pointer-events: none; 

      @media only screen and (min-width: 2560px) {
        height: 55px;
      }

      @media only screen and (max-width: 773px) {
        height: 35px;
      }
    }
  }
}

@media only screen 
and (max-width: 454px) {
  .navbar {
    padding: 0 2.25rem !important;
  }
}