* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  scroll-behavior: smooth;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -o-font-smoothing: antialiased;
  // text-rendering: geometricPrecision;
  font-family: 'Proxima Nova Rg', -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', 'Segoe UI',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #151515;
}

html {
  scrollbar-color: #151515 transparent;
  scrollbar-width: thin;
  background-color: #fafafa;
}

body {
  min-width: 100%;
  // background-color: #fafafa;
}

a {
  color: #000000;
  text-decoration: none;
}

hr {
  border: 1px solid #151515;
  margin: .5rem 0;
}

h1 {
  font-size: 26px;
}

img {
  user-select: none;
  // opacity: 0;
  // transition: opacity .2s ease-in;
}

.noselect {
  user-select: none;
}

.nopointer {
  pointer-events: none;
}

.flash-msg {
  // &__area {
  //   display: flex;
  //   flex-direction: column;
  //   grid-gap: .5rem;
  //   width: min-content;
  //   position: absolute;
  //   top: 1rem;
  //   right: .75rem;
  // }

  background-color: #ebffd8;
  // height: max-content;
  max-height: 7rem;
  width: max-content;
  max-width: 100%;
  // margin-bottom: 1rem;
  padding: 1.75rem 2.25rem;
  border-radius: 4px;
  border: 1px solid #00000008;
  font-family: 'Proxima Nova Lt';
  font-size: 16px;
  box-shadow: 3px 3px 8px 1px #0000000d;
  transition: opacity .3s ease-out;
}

.App {
  min-height: 100vh;
  // height: calc(100vh + 3.5rem + 1px);
}

.wrapper {
  position: relative;
  height: auto;
  min-height: calc(100vh - 4.5rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // overflow: hidden;

  @media only screen and (min-width: 2560px) {
    min-height: calc(100vh - 6rem);
  }

  @media only screen and (max-width: 773px) {
    min-height: calc(100vh - 3.875rem);
  }

  &__bg {
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: tan;
    background-image: url('https://fasales.ru/img/bg/1.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    filter: brightness(.66) grayscale(.33);
    transform: scale(1.05);
  }
}

.hr-light {
  border-bottom: none;
  border-color: #8c8c8c;
}

.text-semi-light {
  color: #8c8c8c;
}

.text-light {
  color: #b5b5b5;
}

.text-sm {
  font-size: 15px;
}

.frame {
  box-sizing: border-box;
  min-width: 33.33%;
  // width: min-content;
  // max-width: 75%;
  height: max-content;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 9.5rem;
  background-color: #ffffff;
  box-shadow: 1px 2px 15px #00000017;
}

.btn {
  width: 100%;
  padding: .75rem 1rem;
  font-size: 13px;
  border: 1px solid #151515;
  border-radius: 7px;
  background-color: #151515;
  color: #f1f1f1;
  line-height: 1.25rem;
  letter-spacing: .3px;
  text-transform: uppercase;
  font-family: 'Proxima Nova Lt';
  font-weight: 600;
  transition: .2s ease-out;
  cursor: pointer;

  &:disabled {
    border-color: #515151;
    background-color: #515151;
    color: #f0f0f0;

    &:hover, &:focus, &:active {
      box-shadow: inset 0 0 6px #0000002b;
      text-shadow: none;
    }
  }

  &:hover, &:focus, &:active {
    box-shadow: 0 4px 5px #00000054;
    text-shadow: 0 0 2px #ffffff9f;
  }
}

.img-btn {
  cursor: pointer;
}

@media only screen 
and (max-width: 1365px) {
  // .frame {
  //   width: 50%;
  //   padding: 0 1.75rem;
  // }
}

@media only screen 
and (max-width: 1024px) {
  // .frame {
  //   width: 70%;
  //   border: none;
  //   padding: 0 1.75rem;
  // }

  .footer__item {
    &--right {
      max-width: 50%;
    }
  }
}

@media only screen 
and (max-width: 959px) {
  html {
    background-color: #fff;
  }

  .wrapper__bg {
    display: none;
  }
  
  .footer__item {
    &--right {
      max-width: 100%;
    }
  }

  .frame {
    width: 100% !important;
    border: none;
    border-radius: 0;
    margin: 0;
    //margin: 1rem 0;
    // padding: 0 1.75rem;
    box-shadow: none;
  }
}

@media only screen 
and (max-width: 454px) {
  .wrapper {
    padding: 0;
  }

  // .frame {
  //   width: 100%;

  //   border: none;
  //   border-radius: 0;
  //   box-shadow: none;
  //   padding: 0;
  // }

  .about {
    padding: 2.5rem 2.25rem !important;
  }
}