.spinner-compact {
  // position: absolute;
  // top: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 773px) {
    margin-top: 5rem;
  }

  &__element {
    display: inline-block;
    width: .7rem;
    height: .7rem;
    margin: 0 .2rem;
    border-radius: 50%;
    background-color: #151515;
    animation: blinking 1s infinite;

   
  }

  @keyframes blinking {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

}

