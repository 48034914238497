  .coupon{
    display: flex;
    flex-wrap:wrap;
    min-height: 50vh;
    align-items: center;
    justify-content: center; 
    
    &__outer{
        position: relative;
        margin: 2.75rem auto 5rem auto;
        padding: 1.5rem;

        &-back{         
                position: absolute;
                top: 40px;
                right: 40px;

                &-btn{
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    background: #FFF;
                    border: 2px solid #000;
                    box-sizing: border-box;
                    border-radius: 50%;
                    padding: .5rem;
                    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));

                    &:hover{
                        transition: all .3s ease-out;
                        transform: scale(1.05);
                        box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.1);
                    }
                }
            }
    }

    &__item{
        display: flex;
        flex-direction: column;
        background: #FFF;
        padding: 2.5rem 3.5rem 2.5rem 3.5rem;
        max-width: 500px;
        border: 1px solid #E8E8E8;
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.15);
        border-radius: 20px;

        &-top{
            display: flex;
            justify-content: space-between;
            margin: 0 0 2rem 0;

            &-help{
                user-select: none;
                cursor: pointer;
                padding: .25rem .5rem;
                background: #FFF;
                border: 2px solid #000;
                box-sizing: border-box;
                border-radius: 6px;
                font-family: Proxima Nova Lt;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #000;
                filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
                transition: all .3s ease-out;

                &:hover{
                    box-shadow: 5px 5px 10px 0px #15151525;
                    background: #2c2c2c;   
                    color: #F3F3F3;
                }
            }
            
            &-closebtn{
                user-select: none;
                cursor: pointer;
                padding: .25rem .5rem;
                background: #000;
                border: 2px solid #000;
                box-sizing: border-box;
                border-radius: 6px;
                filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
                font-family: Proxima Nova Lt;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #FFF;
                transition: all .3s ease-out;

                &:hover{
                    box-shadow: 5px 5px 10px 0px #15151525;
                    background-color: #2c2c2c;   
                }
            }
        }

        &-name{
            font-family: Proxima Nova Lt;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            color: #000;
            align-self: flex-start;
            margin: 0 .25rem .75rem .25rem;
        }

        &-title{
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 26px;
            color: #000;
            padding: 1rem 0;
            margin: 0 .25rem .5rem .25rem;
            border-top: 2px solid #000;
        }

        &-promo{
            margin: .5rem .35rem .5rem .35rem;
            cursor: pointer;

            &-link{
                padding: .5rem 1rem;
                border: 1px solid #000;
                box-sizing: border-box;
                border-radius: 10px 10px 0px 0px;
                letter-spacing: .5px;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                color: #FFF;
                background-color: #000;
            }

            &-code{
                padding: .5rem .5rem;
                background-color: #F3F3F3;
                border: 1px solid #C0C0C0;
                border-top-width: 0;
                box-sizing: border-box;
                border-radius: 0px 0px 10px 10px;
                letter-spacing: .5px;
                font-family: Proxima Nova Lt;
                font-style: normal;
                font-weight: bold;
                font-size: 23px;
                line-height: 31px;
                text-align: center;
                color: #3F3F3F;
            }

        }

        &-btn{
            cursor: pointer;
            border-radius: 10px;
            padding: .75rem 3rem;
            background: #000;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
            width: fit-content;
            font-family: Proxima Nova Lt;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            color: #FFF;
            text-align: center;
            transition: all .3s ease-out;
            letter-spacing: 0.01em;
            text-transform: uppercase;

            &:hover{
                box-shadow: 5px 5px 10px 0px #15151525;
                background-color: #2c2c2c;   
            }
        }

        &-link{
            margin: 1.5rem auto 0 auto;
        }

    }


    &__message{

        display: flex;
        flex-direction: row-reverse;
        // justify-content: ;
        width: 100%;

        &-box{
            position: absolute;
            right: 260px;
            margin: 0 0 0 .5rem;
            display: block;
            clear: both;

            & span {
                padding: 1.5rem 1.25rem;
                display: block;
                width: 200px;
                height: 115px;
                background-size:contain;
                background-position: center center;
                background-repeat: no-repeat;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                align-items: center;
                color: #000000;
            }

        }

        &-shrek{
      
            display: flex;
            margin: 8rem 0 0 0;
          

            &-img{
                display: block;
                margin-left: auto;
                pointer-events: none; 
                width: 100%;
                height: 300px;
                object-fit: cover;
                object-position: 15% 5%;

            }


        }

        @media only screen and (max-width: 773px) {


            &-box{
                right: 190px;

             }
             &-shrek{

                &-img{
                  width: 85%;
                  height: 255px;
                }
          }
        }

        @media only screen and (max-width: 454px) {

            &-box{

                right: 145px;

                & span {
                    padding: 1rem 1rem;
                    width: 173px;
                    height: 100px;
                    font-size: 12px;
                    line-height: 15px;
                }

            }

            &-shrek{

                margin: 7rem 0 0 0;

                &-img{
                    width: 75%;
                    height: 230px;
                }

            }
                
        }


        @media only screen and (max-width: 359px) {


            &-box{
                right: 105px;
            }

            &-shrek{

                margin: 8rem 0 0 0;

                &-img{
                    width: 60%;
                    height: 185px;
                }

            }
                
        }
        @media only screen and (max-width:  316px) {
            &-box{
                margin: 0 0 0 .25rem;
                left: 0;
            }
        }


    }

    &__help{
        margin: 1.5rem auto 0 auto;
        text-align:center;
        width: fit-content; 

        &-label{
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            border-bottom: 1px solid #000;
            text-decoration: none;
            color: #000;
            opacity: 0.5;

            &:hover {
                transition: all .5s ease-out;
                opacity: 0.3;
            }
        }


        &-message{
            margin-top: 0.5rem;
            position: absolute;
            right: -90px;
            padding: .75rem 1rem;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
            color: #1F1F1F;
            background: #F3F3F3;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.2);
            border-radius: 0px 15px 15px 15px;

            &-anim{
                &-enter {
                  &{
                    opacity: .1;
                    transform: translate(40px, 0);
                  }
                  &-active{
                    opacity: 1;
                    transform: translate(-5px, 0);
                    transition: all 400ms ease-out;
                  }
                  &-done{
                    transition: all 100ms ease-out;
                    transform: translate(0, 0);
                  }
                } 

                &-exit{
                    &-active {
                        transform: translate(40px, 0);
                        opacity: .5;
                        transition: all 100ms ease-out;
                    }
                    &-done{
                        opacity: 0;
                    }
                }
            }

            &-anim-lite{
                &-enter {
                    &{
                        opacity: .1;
                        transform: translate(40px, 0);
                    }
                    &-done{
                        transition: all 300ms ease-out;
                        transform: translate(0, 0);
                    }
                } 
                 &-exit{
                    &-active {
                        transform: translate(40px, 0);
                        opacity: .5;
                        transition: all 300ms ease-out;
                    }
                    &-done{
                        opacity: 0;
                    }
                }
            }
        }
    
    }


    @media only screen and (max-width: 959px) {
        min-height: 0;

        &__outer{
            margin: .5rem auto 3rem auto;
             padding: 0 .75rem;
        }

        &__item{
            padding: 1.5rem;
            background: #FFF;
            border: 1px solid #E3E3E3;
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

            &-btn{
                user-select: none;
            }

            &-link{
                margin: 1.5rem auto .5rem auto; 
            }
        }
      
    }


    @media only screen and (max-width: 773px) {
      
        &__item{
          
            &-name{
                font-size: 27px;
                line-height: 33px;
            }
            &-title{
                font-size: 19px;
                line-height: 24px;
            }
            &-promo{
              
                &-code{
                    font-size: 21px;
                    line-height: 28px;
                }
                &-link{
                    font-size: 22px;
                    line-height: 27px;
                }
            }
            &-btn{
                font-size: 18px;
                line-height: 21px;

            }
        }

    }

    @media only screen and (max-width: 454px) {
  


        &__item{
            padding: 1rem;

            &-top{
                margin: 0 0 1rem 0;

                &-help{
              
                    padding: .25rem .5rem;
                    font-size: 18px;
                    line-height: 22px;
                }
                
                &-closebtn{
                    padding: .25rem .5rem;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
          
            &-name{
                font-size: 24px;
                line-height: 30px;
            }
            &-title{
                font-size: 17px;
                line-height: 22px;
            }
            &-promo{
                margin: 0 .35rem .5rem .35rem;
                
                &-code{
                    font-size: 19px;
                    line-height: 25px;
                    padding: .4rem .5rem;
                }
                &-link{
                    font-size: 20px;
                    line-height: 24px;
                    padding: .4rem .5rem;
                }
            }
            &-btn{
                padding: .75rem 2rem;
                font-size: 16px;
                line-height: 19px;
            }

            &-link{
                margin: 1rem auto .5rem auto;
            }
        }


    }

}

