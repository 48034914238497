@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('https://fasales.ru/public/fonts/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('https://fasales.ru/public/fonts/ProximaNova-RegularIt.eot');
	src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
		url('https://fasales.ru/public/fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-RegularIt.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('https://fasales.ru/public/fonts/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('https://fasales.ru/public/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-Bold.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('https://fasales.ru/public/fonts/ProximaNova-BoldIt.eot');
	src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
		url('https://fasales.ru/public/fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-BoldIt.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('https://fasales.ru/public/fonts/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('https://fasales.ru/public/fonts/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('https://fasales.ru/public/fonts/ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('https://fasales.ru/public/fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-Semibold.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('https://fasales.ru/public/fonts/ProximaNova-SemiboldIt.eot');
	src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
		url('https://fasales.ru/public/fonts/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-SemiboldIt.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('https://fasales.ru/public/fonts/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.woff') format('woff'),
		url('https://fasales.ru/public/fonts/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap
}