#landing {
    width: 100%;
    background: linear-gradient(251.47deg, #FFFFFF -7.87%, #E0E0E0 82.24%), #FFFFFF;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 1024px) {
        background: linear-gradient(260.84deg, rgba(255, 255, 255, 0.16) -80.76%, rgba(0, 0, 0, 0.16) 103.16%, rgba(255, 206, 96, 0.16) 103.18%), #FFFFFF;
    }
   
    .frame {

      background: transparent;
      box-sizing: border-box;
      height: max-content;
      border: 0; 
      border-radius: 8px;
      margin-top: 0;
      margin-bottom: 0;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      min-height: calc(100vh - 4.5rem);

    @media only screen and (min-width: 2560px) {
        min-height: calc(100vh - 6rem);
    }

    @media only screen and (max-width: 1024px) {
        flex-direction: column-reverse;
    }

    @media only screen and (max-width: 773px) {
        min-height: calc(100vh - 3.875rem);
    }

    }


    .landing-box{
     
        &__hand{
            display: flex;
            width: 50%;
            margin: auto 5rem 0 1rem;
           
            &-img{
                margin: auto 0 0 auto;   
                object-fit: contain;
                height: 80%;
                width: 80%;
                pointer-events: none; 

                
                @media only screen and (max-width: 1024px) {
                    margin: auto auto 0 3rem;    
                    height: 60%;
                    width: 60%;
                }
                @media only screen and (max-width: 454px) {
                    margin: auto auto 0 0;  
                    height: 80%;
                    width: 80%;
                }
                @media only screen and (min-width: 2560px) {
                    height: 75%;
                    width: 75%;
                }
            }       

            @media only screen and (max-width: 1024px) {
                width: 100%;  
                height: 100%; 
                margin: 1.5rem auto 0;           
            }
          }
         
        &__media{
            // display: flex;
            // align-self: flex-start;
            // flex-direction: column;
            width: 50%;
            margin: 8.5rem auto auto 1rem;

            @media only screen and (max-width: 1440px) {
                margin: 4.5rem auto auto 1rem;
            }

            @media only screen and (max-width: 1024px) {
                margin: 1.5rem auto auto 0;
                width:100%;
            }

            &-title{
                margin: 0 6.5rem 1rem 0;
                padding: .15rem 3.5rem .5rem .5rem;
                font-family: Proxima Nova Lt;
                font-style: normal;
                font-weight: bold;
                font-size: 56px;
                line-height: 67px;
                letter-spacing: -0.0125em;
                color: #000;
                border-bottom: 1px #000 solid;
                user-select: none;

                @media only screen and (max-width: 1440px) {
                    font-size: 47px;
                    line-height: 57px;
                }

                @media only screen and (max-width: 1024px) {
                    font-size: 43px;
                    line-height: 52px;
                    margin: 0 3.5rem 1rem 1.15rem;
                    padding: 0 .5rem .25rem 0;
                }

                @media only screen and (max-width: 454px) {
                    font-size: 33px;
                    line-height: 39px;
                    margin: 0 2.5rem 1rem 1.15rem;
                }

                @media only screen and (min-width: 2560px) {
                    font-size: 65px;
                    line-height: 79px;
                    max-width: 1024px;
                }

            }
            &-group{
                margin: 2.5rem auto auto 0;
                @media only screen and (max-width: 1024px) {
                    margin: 1.5rem auto auto 0;
                }

                @media only screen and (max-width: 454px) {
                    margin: 1rem auto auto 0;
                }
            }          
        }
    }
  }