.result {
  // padding: 4rem 3rem;
  padding-bottom: 0;
  text-align: center;

  &__picture {
    // display: inline-block;
    width: 14rem;
    height: 14rem;
    // max-height: 28vh;
    border-radius: 100%;
    margin: 0 auto;
    // font-size: 9rem;
    background-color: #f4f4f4;
    background-position: center;
    background-size: cover;
    // pointer-events: none;
    box-shadow: 0 2px 8px #00000025;
  }

  &__score {
    color: #151515;
    margin-top: 1.25rem;
    font-size: 4rem;
  }

  &__text {
    color: #151515;
    margin-top: .25rem;
    margin-bottom: 1.5rem;
    font-family: 'Proxima Nova Lt';
    font-weight: 600;
    font-size: 18px;
  }

  &__share {
    margin-bottom: 1.75rem;

    &-btn {
      padding: 0 .2rem !important;
    }
  }
}

@media only screen 
and (max-width : 454px) {
  .result {
    // padding: 3rem 2rem 0;
  }
}