.details {
  padding: 0 .25rem;

  @media only screen and (max-width: 454px) {
    padding: 0;
  }

  .promocodes {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    margin: .5rem auto 0;
    padding: .75rem 1rem;
    letter-spacing: -.1px;
    min-height: 50vh; //FIX

    @media only screen and (max-width: 773px) {
      min-height: 0;
      padding: 0 1rem 0 1rem;
    }
  }

  &__btn-back {
    margin: auto auto  auto 1rem;
    border: 3px solid #151515;
    padding: .5rem;
    border-radius: 50%;
    vertical-align: middle;

    &:hover{
    
      transition: all .3s ease-out;
      transform: scale(1.05);
      box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 959px) {
      height: 1.5rem;
      padding: .35rem;
      &:hover{
        transform: none;
        box-shadow: none;
      }
    }

    @media only screen and (max-width: 454px) {
      margin: auto .1rem auto .25rem;
      height: 1.25rem;
      padding: .2rem;
    }
  }

  &__name {
    font-size: 36px;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  }

  &__head{   
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;


    @media only screen and (max-width: 773px) {
      position: -webkit-sticky; 
      position: sticky;
      top: 0;  
      padding: .5rem 0;
      margin-bottom: 0.75rem;
      background: linear-gradient(279.61deg, rgba(215, 242, 236, 0.16) 10.75%, rgba(255, 206, 96, 0.16) 80.48%, rgba(255, 206, 96, 0.16) 100%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
      z-index: 99;
     }

     @media only screen and (max-width: 454px) {
      padding: .5rem;
      margin-bottom: 0.5rem;
     }

     & a{
       margin: auto 0;
     }


    &-filter{

       display: flex;
       align-items: center;
       justify-content: flex-end;
       flex-wrap: wrap;

      &-label{
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        color: #000;
        padding: .25rem .5rem;
      }
      
      &-value{
        border: 2px solid #151515;
        box-sizing: border-box;
        border-radius: 40px;
        padding: .25rem 1rem;
        margin: .25rem .35rem; 
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        background-color: #f9fefa;
        color: #151515;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);

        &-none{
          pointer-events: none; 
           opacity: 0.5;
           border: 2px solid #151515;
          box-sizing: border-box;
          border-radius: 40px;
          padding: .25rem 1rem;
          margin: .25rem .35rem; 
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          background-color: #f9fefa;
          color: #151515;
        }

        &-selected{
          border: 2px solid #151515;
          box-sizing: border-box;
          border-radius: 40px;
          padding: .25rem 1rem;
          margin: .25rem .35rem; 
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
          color: #E6E6E6;
          background-color: #151515;
          transition: background-color .4s ease-out;

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          cursor: pointer;
          color: #E6E6E6;
          background-color: #151515;
          transition: background-color .4s ease-out;
        }
      }

      @media only screen and (max-width: 959px) {
        margin: 0;
        
        &-value{
          user-select: none;
          padding: .25rem .75rem;
          margin: .25rem .35rem .25rem 0; 
          background-color: transparent;
          font-size: 16px;
          line-height: 20px;
         

          &-none{
            background-color: transparent;
            margin: .25rem .35rem .25rem 0; 
            padding: .25rem .75rem;
            font-size: 16px;
            line-height: 20px;
          }

          &-selected{
            user-select: none;
            margin: .25rem .35rem .25rem 0;
            padding: .25rem .75rem; 
            font-size: 16px;
            line-height: 20px;
          }
        }      
      }

      @media only screen and (max-width: 454px) {
        
        &-value{
          padding: .15rem .5rem;
          margin: .25rem .25rem .25rem 0; 
          font-size: 14px;

          &-none{
            margin: .25rem .25rem .25rem 0; 
            padding: .15rem .5rem;
            font-size: 14px;
          }

          &-selected{
            margin: .25rem .25rem .25rem 0;
            padding: .15rem .5rem;
            font-size: 14px;
          }
        }      
      }
    }
  }

  &__info{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: .5rem 3.5rem 2rem;

    &-label{
      //font-family: Proxima Nova Lt;
      max-width: 380px;
      margin: 0 1rem auto 0;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 26px;
      color: #000;
    }



    &-media{
      display: flex;
      box-sizing: border-box;
      padding: .4rem .5rem .25rem .5rem;
      border: 2px solid #151515;
      border-radius: 10px;

      .icon{
        margin: 0 .75rem;

        &__img{
          display: block;
          margin: 0 auto;
      }


      }

        &-item{
          display: block;
          font-family: Proxima Nova Lt;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 31px;
          text-align: center;
          color: #000;
      
        }  
    }
    @media only screen and (max-width: 1279px) {
      margin: .5rem 2.5rem 2rem;
      &-label{
        font-size: 24px;
        line-height: 24px;
      }
      &-media{
        .icon{
          margin: 0 .5rem;
       }
        &-item{
          font-size: 23px;
          line-height: 27px;
        }
      }
    }
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (                min-resolution: 192dpi) and (min-width: 1280px) and (max-width: 1600px) ,
    only screen and (                min-resolution: 2dppx)  and (min-width: 1280px) and (max-width: 1600px)  { 
      margin: .5rem 1.5rem 2rem;
      &-label{
        font-size: 24px;
        line-height: 24px;
      }
      &-media{
        .icon{
           margin: 0 .5rem;
        }
        &-item{
          font-size: 23px;
          line-height: 27px;
        }
      }
    }
  }
}

 //MARK: Animations 
 .promo-fade{
  &-enter{
    &{
      opacity: .1;
      transform: translate(0, 40px)
    }
    &-active:not(.promo-fade-appear-done){ 
      opacity: 1;
      transform: translate(0, -5px);
      transition: all 500ms ease-in;
    }

    &-done:not(.promo-fade-appear-done){
      transform: translate(0, 0);
      transition: all 200ms ease-out;
    }
  }
}

.promo-fade-lite{
  &-enter{
    &{
      opacity:0;
      transform: translate(0, 40px)
    }

    &-done{
      transform: translate(0, 0);
      transition: all 200ms ease-out;
    }
  }
}

.message-fade{
  &-enter {
    &{
      opacity: .1;
      transform: translate(30px, 0);
    }
    &-active:not(.message-fade-appear-done) {
      opacity: 1;
      transform: translate(-5px, 0);
      transition: all 300ms ease-out;
    }
    &-done:not(.message-fade-appear-done){
      transition: all 200ms ease-out;
      transform: translate(0, 0);
    }
  } 
}

.message-fade-lite{
  &-enter {
    &{
      opacity: 0;
      transform: translate(30px, 0);
    }
    &-done{
      transition: all 200ms ease-out;
      transform: translate(0, 0);
    }
  } 
}

