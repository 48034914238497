.landing-box__media{

    &-item{
      
        padding: 5px;
        margin: 1rem auto 1rem 0;
        user-select: none;
        cursor: pointer;
        width: fit-content;

         &.opened{
            .icon {
               transform: scale(1.05);
            }
        }

        .icon {
            margin: 0 auto 0 2rem;
           
          
            &:hover, &:focus, &:active {
                filter: none!important;
            }
        }

        @media only screen and (max-width: 1440px) {
            margin: .75rem auto .75rem 0;
         }


        @media only screen and (max-width: 1024px) {
            margin: .25rem auto .25rem 0;
          
         .icon__img{
            width: 1.5rem!important;
          }

          .icon {
            margin: 0 auto 0 1rem;
          }
       }
          
        &-text{
            font-family: Proxima Nova Lt;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            letter-spacing: -0.0125em;
            color: #000;

            @media only screen and (max-width: 1440px) {
                font-size: 29px;
                line-height: 36px;
            }
            
            @media only screen and (max-width: 1024px) {
                font-size: 26px;
                line-height: 32px;
            }

            @media only screen and (max-width: 454px) {
                font-size: 21px;
                line-height: 26px;
            }

            @media only screen and (min-width: 2560px) {
                font-size: 40px;
                line-height: 49px;
            }
        }



        

        &-links{
            margin: 1rem auto 0 5.15rem;

            @media only screen and (max-width: 1440px) {
                margin: .75rem auto 0 5.05rem;
            }

            @media only screen and (max-width: 1024px) {
                margin: .25rem auto 0 3rem;
            }
    
            & a{
                  padding-right: 4rem;

                    @media only screen and (max-width: 1440px) {
                        padding-right: 4rem;
                    }

                    @media only screen and (max-width: 1024px) {
                        padding-right: 3.5rem;
                    }
              
                & span{
                    font-family: Proxima Nova Lt;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: -0.0125em;
                    color: #000;
                    border-bottom: 1px #000 solid;
                    user-select: none;

                   
                    @media only screen and (max-width: 1440px) {
                        font-size: 29px;
                        line-height: 36px;
                    }
                    
                    @media only screen and (max-width: 1024px) {
                        font-size: 26px;
                        line-height: 32px;
                    }

                    @media only screen and (max-width: 454px) {
                        font-size: 21px;
                        line-height: 26px;
                    }

                    @media only screen and (min-width: 2560px) {
                        font-size: 40px;
                        line-height: 49px;
                    }


                    &:hover, &:focus, &:active {
                        filter: invert(.75);
                        transition: all .4s ease-out;
                      }
                }
     
            }
        }
        
    }
}  

